import React, { useEffect, useState,useRef } from "react";


import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import ImageProccesor from "./components/ImageProccesor";
import InitialConfiguration from "./components/InitialConfiguration";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import TestWebcamStreamCapture from "./components/testWebcamStreamCapture";



//JM variable para que funcione si no tiene flash y poder debugear en todos lados.
const DEBUG_DESKTOP_JUANMA=false;

const SERVER_URL = "https://admin.septiscan.com";

function App() {
  const [cutNumber, setCutNumber] = useState<number[] | null>(null);
  const  [torchonoff, setTorch] = useState(false);
  const  [configuration, setConfiguration] = useState(false);
  //const initialConfRef:any = useRef(null); 
  //const imageProccesorRef:any = useRef(null); 


  // Constraints
  const videoConstraints: any = {
    width: 512,
    height: 512,
    sampleSize: 10,
    facingMode: "environment",
    zoom: 4,
  };

  const [company_id, setCompany_id]=useState(1)

  const handleConfigurationFinished = (value: number[]) => {
    setCutNumber(value);
  };
  
  const handleFlashChange = (value:boolean) => {
    setTorch(value);
  }
 

  return (
    
    <>
      <Navbar expand="lg" className="bg-body-tertiary mt-1">
        <Container>
          <Navbar.Brand href="#home"><img
              src={process.env.PUBLIC_URL + 'images/logo_app.png'}
             
              height={30}
              className="d-inline-block align-top"
              alt="Septiscan"
            /> V1.0</Navbar.Brand>
          <NavDropdown title="Config" id="collapsible-nav-dropdown" className="dropdown-menu-right"  align={{ lg: 'start' }}>
            <NavDropdown.Item href="#action/3.1">
              Torch: {torchonoff ? "Si" : "No"}
            </NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">
              Configuración
            </NavDropdown.Item>
          </NavDropdown>
        </Container>
      </Navbar>
      <Container >
      
        {!configuration?
             (cutNumber === null ? (
              <InitialConfiguration  
                DEBUG_DESKTOP_JUANMA={DEBUG_DESKTOP_JUANMA} 
                videoConstraints={videoConstraints}
                onConfigurationFinished={handleConfigurationFinished} 
                onFlashChange={handleFlashChange} 
              />
            ) : (
              <ImageProccesor 
              DEBUG_DESKTOP_JUANMA={DEBUG_DESKTOP_JUANMA} 
              videoConstraints={videoConstraints}
              cutNumber={cutNumber}  
              onFlashChange={handleFlashChange}
              company_id={""+company_id}
              SERVER_URL={SERVER_URL}
            />
              // <testWebcamStreamCapture></testWebcamStreamCapture>
              
            )
          ):
            <div className="configuracion"></div>
        }
       
      </Container>
      </>
  );
}

export default App;
