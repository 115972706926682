//#### IMPORTS
import { useState, useRef, useCallback, useEffect } from "react";
import Webcam from "react-webcam";
import cv, {CV_8U,CV_8UC1} from "@techstark/opencv-js";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Ratio from 'react-bootstrap/Ratio';
import Carousel from 'react-bootstrap/Carousel';

//Chart JS
import {
  Chart as ChartJS,  CategoryScale,  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
//END #### IMPORTS

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

type InitialConfigurationProps = {
  DEBUG_DESKTOP_JUANMA:boolean;
  onConfigurationFinished: (value: number[]) => void;
  onFlashChange: (value: boolean) => void;
  videoConstraints:any;
};

const InitialConfiguration: React.FC<InitialConfigurationProps> = ({DEBUG_DESKTOP_JUANMA, videoConstraints, onConfigurationFinished, onFlashChange }) =>{
  
  const carrouselRef:any = useRef(null);
  const webcamRef: any = useRef(null);
  
  
  const [snapshots, setSnapshots] = useState<any[]>([]);


  const [finalResults, setFinalResults] = useState<number[] | undefined>(
    []
  );
  let [histdata, setHistdata] = useState([]);
  let [histdata1, setHistdata1] = useState([]);
  
  const  [torchonoff, setTorch] = useState(false);
  let [mostrandoImagen, setMostrandoImagen] = useState(false);


  /**
   *  Esta funcion se llama cuando se saca la foto
   */
  const handleTakePicture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({width:videoConstraints.width, height:videoConstraints.height});
    setSnapshots([imageSrc]);
    carrouselRef.current.next();
    setMostrandoImagen(true);
    setTimeout(function(){handleAnalyze()},300);
  }, [webcamRef, snapshots]);

  const onloadWebCam = () => {
    console.log("WEBCAM IS LOADED");
   
  };

  const handleAnalyze = () => {
    // TODO: calculate histograms and send results through onConfigurationFinished method
    const imageOne = cv.imread("0");

    const imageSize= imageOne.size();

    // Imagen uno
    cv.cvtColor(imageOne, imageOne, cv.COLOR_RGBA2RGB, 0); 
    cv.cvtColor(imageOne, imageOne, cv.COLOR_RGB2HSV, 0); 

    //JM cambio el centro para el centro de la imagen
    let centro = new cv.Point(Math.round(imageSize.width/2),Math.round(imageSize.height/2));
    
    //JM Cambio la mascara de 0s para que sea igual a la imagen
    let mask = cv.Mat.zeros(imageSize.width,imageSize.height,CV_8U);


    //JM genero el criculo con un radio del 25% de mas chico del width and height
    let radioCircle  = Math.min(imageSize.width/4, imageSize.height/4);
    cv.circle(mask, centro, radioCircle, [1,1,1,0],cv.FILLED);


    let hist = new cv.Mat();
    let hist1 = new cv.Mat();
    
    // Initialise a MatVector
    let matVec = new cv.MatVector();
    // Push a Mat back into MatVector
    matVec.push_back(imageOne);

    try {
      cv.calcHist(matVec, [0], mask, hist, [180], [0, 179]);
    } catch (error) {
      console.error(error);
      throw new Error("ERROR ERROR");
    }
    //console.log(hist.data);

    try {
      cv.calcHist(matVec, [2], mask, hist1, [255], [0, 255]);
    } catch (error) {
      console.error(error);
      throw new Error("ERROR ERROR");
    }
   // console.log(hist1.data);

    cv.transpose(hist,hist);
    setHistdata(hist.data);

    cv.transpose(hist1,hist1);
    setHistdata1(hist1.data);

    let minVal = new  cv.Mat();
    let resmaxloc: any = cv.minMaxLoc(hist,minVal);
    let max = resmaxloc.maxLoc.x;  

    let res = [max-6,max+3,Math.round(imageSize.width*imageSize.height*0.625)];

    setFinalResults(res);
  };

  const handleFinish = () => {
    if (finalResults) {
      onConfigurationFinished(finalResults);
    }
  };

  const turnTorch = () => {
    setTorch((torchonoff) => !torchonoff);
    onFlashChange(!torchonoff);
    // Agrego para prender el flash cuando saca las imagenes de calibracion
    let track = webcamRef?.current?.stream.getVideoTracks()[0];
    const capa = track.getCapabilities();
    const settings = track.getSettings();
    // console.log(capa);
    // console.log(settings);

    //JM agrego esto para poder debugear y probar en el navegador 
    if(!DEBUG_DESKTOP_JUANMA){


      if (!("zoom" in settings)) {
        return Promise.reject("Zoom is not supported by " + track.label);
      }
      track.applyConstraints({
        advanced: [{ 
          torch: !torchonoff,
          // focusMode: "continuous",
          // whitebalanceMode: "manual"
        }],
      });
    }
  };

 const volverATomar= ()=>{
  carrouselRef.current.prev();
  setMostrandoImagen(false);
 }

  // Configuracion Chart JS
  const options = {
    responsive: true,
   width:"100%",
   maintainAspectRatio: false,
    aspectRatio: 1,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  };

  const labels = [];

  for (var i = 700; i <= 1020; i++) {
    labels.push(i);
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'H',
        data: histdata.slice(0,100), 
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'V',
        data: histdata1.slice(700,1020),
        backgroundColor: 'rgba(99, 255, 132, 0.5)',
      },
    ],
  };

 

  return (
    
    <>
      <Row className="mt-2">
        <Col>
        {/* Seccion de carrousele para mostrar video etc */}
          <Carousel ref={carrouselRef} controls={mostrandoImagen} indicators={false} interval={null}>
            <Carousel.Item  >
              <Ratio aspectRatio="1x1">
                <Webcam
                    ref={webcamRef}
                    onUserMedia={onloadWebCam}
                    imageSmoothing={false}
                    screenshotFormat="image/jpeg"
                    // style={{ opacity: 1, width: 200, height: 200 }}
                    videoConstraints={videoConstraints}
                    audio={false}
                    screenshotQuality={1}
                  />
              </Ratio>
              <div className="circle" ></div>
            </Carousel.Item>
      
            <Carousel.Item > 
              {snapshots.map((image) => (
                  <Col className="Col-6">
                  <img
                    id= "0"
                    key= "0"
                    className="inputImage"
                    alt="input"
                    src={image}
                  />
                  </Col>
                ))}
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      {/* Seccion de boton on/off led */}
      <Row className="mt-1">
        <Col>
          <div className="d-grid gap-2">
            <Button variant="primary"  size="lg" onClick={turnTorch}>LED ON/OFF</Button>
          </div>
        </Col>
      </Row>
      {/* Seccion de valor de corte */}
      <Row  className={finalResults!=null && finalResults.length>1?"mt-3":"visually-hidden"}>
        <Col className="col-12 ">
          <h1 className="d-flex justify-content-center white_text"> Corte {finalResults?.at(1)} </h1>
         
            {/* <br />
            <span> Corte superior: {finalResults?.at(1)} </span>
            <br />
            <span> Area: {finalResults?.at(2)} </span> */}
            {/* <div> <Line options={options} data={data} /></div> */}
        </Col>
      </Row>
          {/* Seccion de grafica */}
      <Row  className={finalResults!=null && finalResults.length>1?"":"visually-hidden"}>  
        <Col className="col-12">
          {/* <Line options={options} data={data} width={"100%"}  className="initialConfGraph"/> */}
        </Col>
      </Row>
      {/* Seccion de footer donde estan los botones para tomar imagen etc */}  
      <Container className="fixed-bottom mb-2 mr-1">
        <Row>
          <Col className={torchonoff ?'visually-hidden':''}>
            <div className="d-grid gap-2 justify-content-center ">
              <p className="blue_over_green_text">Por favor prenda el flash para que pueda procesar</p>
            </div>
          </Col>

          <Col className={!torchonoff ?'visually-hidden':(mostrandoImagen?'visually-hidden':'')}>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg"  onClick={handleTakePicture} >TOMAR IMAGEN</Button>
            </div>
          </Col>
        
          <Col className={!torchonoff ?'visually-hidden':(!mostrandoImagen?'visually-hidden':'col-6')}>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg"  onClick={volverATomar}  >Volver A Tomar</Button>
            </div>
          </Col>
          <Col className={finalResults && !mostrandoImagen?'visually-hidden':'col-6'}>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleFinish}  >Continuar</Button>
            </div>
          </Col>
        </Row>     
      
      </Container>
      </>
  );
}

export default InitialConfiguration;
