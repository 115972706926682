import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import cv, { float } from "@techstark/opencv-js";

import { processImage } from "../utils/imageProcessing";
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { Col, InputGroup, ProgressBar } from "react-bootstrap";

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Ratio from 'react-bootstrap/Ratio';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';

type ImageProccesorProps = {
  DEBUG_DESKTOP_JUANMA:boolean;
  cutNumber?: number[];
  onFlashChange: (value: boolean) => void;
  videoConstraints:any;
  company_id:string;
  SERVER_URL:string;
};

//Tamaño de los chunks en ms
const CHUNCKS_MS_LENGTH=100;
//Tiempo maximo que graba el video
const MAX_VIDEO_LENGTH_SECONDS=20;
//Tiempo luego que termine de medir el TRC que sigue guardando
const TIEMPO_LUEGO_MEDIDA_SECONDS=5;

//TIEMPO EN SEGUNDOS DEL COUNTER QUE TIEENE QUe CONTAR LOS MEDICOS
const TIEMPO_MAX_COUNTER = 10;

const ImageProccesor : React.FC<ImageProccesorProps> = ({DEBUG_DESKTOP_JUANMA,videoConstraints, cutNumber,company_id ,SERVER_URL }) =>{
  
  const webcamRef: any = useRef(null);
  const imgRef: any = React.useRef(null);
  const inRef: any = React.useRef(null);
  const [detectedPixels, setDetectedPixels] = useState(0);
  const [detectedPixelsPRES, setDetectedPixelsPRES] = useState(0);
  const alfa: number = 90;
  const beta: number = 20;
  const [cantidad_de_corte, setCantidad_de_corte] = useState(-1);
  const [tiempo_init, setTiempo_init] = useState(-1);
  const [tiempo_end, setTiempo_end] = useState(-1);
  let DEBUG = true;
  let [resultado, setResultado] = useState(0);
  let [torchonoff, setTorch] = useState(false);
  const torchState = useRef(torchonoff);
  let [counter, setCounter] = useState(0);
  const [datatrc, setDatatrc] = useState<number[]>([]); // Establecer el tipo explícitamente como un array de números
  const labels: number[] = [];
  const largoarray=500;
  let [chart,setChart] = useState(false);
  let [percentage,setPercentage] = useState(0);
  

  const [timeOut,setTimeOut] = useState(1/60*1000);

  // AGREGO ESTO PARA QUE guarde el video
 
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
 
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState<Blob[]>([]);

  
  //AGREGO ESTO PARA el modal
  let [showModal, setShowModal] = useState(false);
  const [videoURL, setVideoURL] = React.useState<string | null>(null);
  const [uploadProgress, setUploadProgress] = React.useState<number>(0);

  const[sujetoId, setSujetoId] = React.useState<string>("");

  const [showWaitMessage, setShowWaitMessage] = useState(false);

  // Muevo arriba lo del flash para qactualizarlo y que no arranque a tomar las primeras medidas
  // Torch on off
  const turnTorch = () => {
    setTorch((torchonoff) => !torchonoff);
    let track = webcamRef?.current?.stream.getVideoTracks()[0];
    const capa = track.getCapabilities();
    const settings = track.getSettings();
    // console.log(capa);
    // console.log(settings);

    //JM agrego esto para poder debugear y probar en el navegador 
    if(!DEBUG_DESKTOP_JUANMA){
      if (!("zoom" in settings)) {
        return Promise.reject("Zoom is not supported by " + track.label);
      }
      track.applyConstraints({
        advanced: [{ 
          torch: !torchonoff,
          // focusMode: "continuous",
          // whitebalanceMode: "manual"
        }],
      });
    }
  };
  
  for (var i=0;i<largoarray;i++){
    labels.push(i);
  };

  function actDataTRC(pixels: number) {
    if (datatrc.length > largoarray) {
      setDatatrc([]);
    } else {
      setDatatrc(prevDatatrc => [...prevDatatrc, pixels]);
    }
  };

  //Uso esta variable para ir calculando el contador
  const [tiempo, setTiempo] = useState<Date | null>(null);
  const tiempoRef=useRef(tiempo);

  /**
   *  Use effect  que mantiene el algoritmo
   */
  useEffect(() => {
    const intervalo = setInterval(() => {
      // Actualiza el tiempo cada segundo
      setTiempo(new Date());
    },timeOut);

    setPercentage(detectedPixels*100/(detectedPixels+detectedPixelsPRES));
    if (!chart) {
      actDataTRC(percentage);
      setChart(true);
    } else {setChart(false)}

    if (percentage < beta) {
      if (counter > 0){
        setTimeout(function(){setCounter(counter-1)},1000);
      }
      //Aca estoy apretado
      setCantidad_de_corte(percentage);
      setTiempo_init(-1);
    } else if (percentage >= beta && cantidad_de_corte > -1) {
      //Aca el dedo se esta llenando de sangre
      if (tiempo_init == -1) {
        //Aca entra la primera vez que el detectedPixels < alfa
        setTiempo_init(Date.now());      
      }
      setCantidad_de_corte(-1);
    } else if (
      tiempo_init > -1 &&
      percentage > alfa 
    ) {
      setTiempo_end(Date.now());
    } else if (percentage < beta && cantidad_de_corte > -1) {
      //NO tengo que entro aca NUNCA 
    }
    if (tiempo_init > -1 && tiempo_end > -1) {
      setShowWaitMessage(true);
      //Aca calculo el tiempo y reinicio los contadores podria entrar en la linea luego de console.log("tiempo end =" + tiempo_end);
      if (DEBUG)
        console.log(
          "TIEMPO TRANSCURRIDO ES = " +
            (tiempo_end - tiempo_init) / 1000 +
            " segundos"
        );
      setTiempo_init(-1);
      setTiempo_end(-1);
      setCantidad_de_corte(-1);
      setResultado((resultado = (tiempo_end - tiempo_init) / 1000));
      setCounter(10);
      // setTimeout(()=>{
      //   showPopUpAlert();
      //   setShowWaitMessage(false);
      // },TIEMPO_LUEGO_MEDIDA_SECONDS*1000);
    }
    return () => clearInterval(intervalo); 
  }, [tiempo]);
  
    /**
     * Use efect que actualiza el valor del torch
     */
  useEffect(() => {
    torchState.current = torchonoff; // Actualiza la referencia con el nuevo valor
  }, [torchonoff]);

    /** 
    * Use efect para configurar el timer
    */
  useEffect(()=>{
    const correrTodo= async ()=>{
      if (webcamRef.current && webcamRef.current.video) {
        const video = webcamRef.current.video;
        const canvasInput = imgRef.current;
        const contextInput = canvasInput.getContext('2d');
        const canvasOutput = inRef.current;
        const contextOutput = canvasOutput.getContext('2d');

        const img = cv.imread(canvasInput);
        const imgSize= img.size();
        if (contextInput) {
          // Dibujar el video en el canvas
          contextInput.drawImage(video, 0, 0, imgSize.width, imgSize.height);
          const proccessedData = processImage(img,cutNumber);
          
          if (torchState.current || DEBUG_DESKTOP_JUANMA) {        
            setDetectedPixels(proccessedData.detectedPixels);
            setDetectedPixelsPRES(proccessedData.detectedPixels2);
            cv.imshow(inRef.current, proccessedData.image);
          }else{
            //console.log("Torchonoff is off");
            contextOutput.drawImage(video, 0, 0, imgSize.width, imgSize.height);
          }
          img.delete();
          //JM agrego la siguiente linea para que borre la memoria tambien de la imagen generada
          proccessedData.image.delete();

        }
      }
      setTimeout(correrTodo,timeOut);
    }
    setTimeout(correrTodo,timeOut);
  },[]);

  const setupCamera = async() =>  {
    try {
      setCapturing(true);
      if (webcamRef.current && webcamRef.current.stream) {
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
          mimeType: "video/webm"
        });
        mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
        mediaRecorderRef.current.start(CHUNCKS_MS_LENGTH); // Start recording in 100ms chunks
      }

      const mimeType = 'video/webm'; // Tipo MIME compatible
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        console.error(`MIME type ${mimeType} is not supported`);
        return;
      }
    } catch (error) {
      console.error("Error accessing the camera: ", error);
    }
  };

  const handleDataAvailable = React.useCallback(

    ({ data }: { data: Blob }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => {
          const newChunks = prev.concat(data);
          if (newChunks.length > MAX_VIDEO_LENGTH_SECONDS*10) {
            newChunks.shift(); // Remove the oldest chunk to keep only the last 20 seconds
          }
          return newChunks;
        });
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
    }
  }, [mediaRecorderRef, setCapturing]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, { type: "video/webm" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      a.href = url;
      a.download = "last-20-seconds.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      
    }
  }, [recordedChunks]);

  /**
   * Agrego el video 
   */
  const handleVideoGenerated=React.useCallback(() => {
    // setVideoURL(url);
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, { type: "video/webm" });
      const url = URL.createObjectURL(blob);
      setVideoURL(url);
      setShowModal(true);
      // const a = document.createElement("a");
      // document.body.appendChild(a);
      // a.style.display = "none";
      // a.href = url;
      // a.download = "last-20-seconds.webm";
      // a.click();
      // window.URL.revokeObjectURL(url);
      //setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const onloadWebCam = () => {
    //debugger;
    setupCamera();
  };

  const reinicarPantalla =() =>{
    window.location.reload(); 
  }

  const showPopUpAlert = () => {
    handleStopCaptureClick();
    handleVideoGenerated();
  }

  /* Aggreo parte de manejo del modal */

  /**
   * fucion que se llama cuando se cierra el modal
   * @param param0 
   */
  const handleCloseModal = ()=>{
    setShowModal(false);
    setRecordedChunks([]);
    setResultado(0);
    setupCamera();
  };


  
  const handleUpload = async () => {
    if (recordedChunks.length > 0 && sujetoId.length>0) {
      let video_length = recordedChunks.length*10;
      const blob = new Blob(recordedChunks, { type: "video/webm" });
      
      const formData = new FormData();
      formData.append("video", blob, "last-20-seconds.webm");
      formData.append("video_length", ""+video_length);
      formData.append("alfa", cutNumber?""+cutNumber[0]:"");
      formData.append("beta", cutNumber?""+cutNumber[1]:"");
      formData.append("trc", resultado?""+resultado:"");
      let other_data ={'Sujeto ID':sujetoId,'graph_data':datatrc};
      formData.append("other_data", JSON.stringify(other_data));
      formData.append("company_id", company_id);

      console.log(JSON.stringify(formData));
      const xhr = new XMLHttpRequest();
      xhr.open('POST', SERVER_URL+'/api/videos', true);

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          setUploadProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200 || xhr.status === 201) {
          //alert('Upload successful!');
        } else {
          alert('Upload failed!');
          console.error('Upload error:', xhr.status, xhr.responseText);
        }
        setUploadProgress(0);
        setShowModal(false);
      };

      xhr.onerror = () => {
        console.error('Upload error:', xhr.status, xhr.responseText);
        alert('Upload failed!');
        setUploadProgress(0);
        setShowModal(false);
      };

      xhr.send(formData);
    }
  };


  /*END  Agreo parte de manejo del modal */




  // Configuracion Chart JS
  const options = {
    animation: {
      duration: 0, // establece la duración de la animación a 0 para desactivarla
    },
    scales: {
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: '[%]',
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Curva TRC',
        data: datatrc, 
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  return (
    <>
    <Webcam
          ref={webcamRef}
          
          onUserMedia={onloadWebCam}
          imageSmoothing={false}
          screenshotFormat="image/jpeg"
          style={{ position: "absolute", opacity: 0, width: 200, height: 200 }}
          videoConstraints={videoConstraints}
          audio={false}
          screenshotQuality={1}
          
        />
     
        <canvas style={{width:"1px", height:"1px"}}
            
            ref={imgRef}
          />
     
    <Row className="camera">
      <Col className="col-12">
      <Ratio aspectRatio="1x1">
        <canvas
            ref={inRef}
          />
          </Ratio>
          {counter>0 &&
          (<div className="counter-overlay">
            {counter}
          </div>
          )
          }
    </Col>
    </Row>
    <Row className="mt-2 mb-1">
      <Col>
        <div className="d-grid gap-2">
        <Button variant="primary"  size="lg" onClick={turnTorch}>LED ON/OFF</Button>
        </div>
        
      </Col>
    </Row>
    <Row className="mt-3 white_text mb-4">
      <Col>
        <span> Pixeles detectados: {detectedPixels}</span>
      </Col>
      <Col> 
        <span> El TRC es de: {resultado} seg. </span>
      </Col>
    </Row>
    <Row className="blue_over_green_text">
      <Col className="col-4">Contador: {counter}</Col>
      <Col className="col-12 white_background"> <Line options={options} data={data} /></Col>
    </Row>
    {/* Seccion de footer donde estan los botones para tomar imagen etc */}  
    {/* <Container className="fixed-bottom mb-2 mr-1">  
        <Row className="">
          <Col>
            <div className="d-grid gap-2">
            <Button variant="primary"  size="lg" onClick={reinicarPantalla}>Reiniciar</Button>
            </div>
            
          </Col>
           {/* //ESTE CODIDOG ERA PARA PROBAR EL  POPUP 
           
            <Col>
            <div className="d-grid gap-2">
              <Button variant="primary"  size="lg" onClick={showPopUpAlert}>Show Popip</Button>
            </div>
            
          </Col> 

        </Row> 
    </Container> */}

    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title> Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Ratio aspectRatio="1x1">
              <video controls src={videoURL?videoURL:''} typeof="video/webm"></video>
          </Ratio>
          
          <Form className="mt-4">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Label className="white_text" >TRC</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control  type="text" readOnly={true} value={resultado} />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                  <Form.Label column="lg" className="white_text">Valor Corte </Form.Label>
                  </Col>
                  <Col>
                    <Form.Control  type="text" readOnly={true} value={cutNumber?cutNumber[1]:""} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Label className="white_text">Corte Inferior</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control  type="text" readOnly={true} value={cutNumber?cutNumber[0]:""} />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                  <Form.Label column="lg" className="white_text" >Cant Pixeles</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control  type="text" readOnly={true} value={cutNumber?cutNumber[2]:""} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col className="col-3">
                    <Form.Label className="white_text">Sujeto ID</Form.Label>
                  </Col>
                  <Col className="col-7">
                    <Form.Control  type="text"  placeholder="Indentificador Sujeto" value={sujetoId} onChange={(e) => setSujetoId(e.target.value)} isInvalid={sujetoId.length==0}/>
                  </Col>
                </Row>
              </Col>
            </Row>            
           
          </Form>
          <div className="mt-2">
            <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} />
          </div>
            
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Reiniciar
          </Button>
          <Button variant="success" onClick={handleDownload}>
            Download
          </Button>
          <Button variant="success" onClick={handleUpload} disabled={sujetoId.length==0}>
            Upload
          </Button>
          
        </Modal.Footer>
      </Modal>
   
    </>

   
  );
}

export default ImageProccesor;
