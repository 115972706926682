import cv, { CV_8UC1, CV_32S, CV_8U, CV_MAX_DIMS, CV_8UC3, bitwise_not, bitwise_and } from "@techstark/opencv-js";

export function processImage(img,cutNumber) {
  //JM saco esto
  //const original = img;
  const res = new cv.Mat();
  //JM cambio para que copia el original
  //let image2 = original.clone();
  let image2 = img.clone();

  
  let res1 = new cv.Mat();
  let res2 = new cv.Mat();

  cv.cvtColor(image2, image2, cv.COLOR_RGBA2RGB,0);
  cv.cvtColor(image2, res, cv.COLOR_RGB2HSV,0);

  // Primer filtro para pixeles sin presionar
  let lowerb = cv.matFromArray(1, 3, CV_8UC1, [0, 0, 0]);
  let upperb = cv.matFromArray(1, 3, CV_8UC1, [cutNumber[1], 255, 255]);
  // Segundo filtro para pixeles presionados
  let lowerb2 = cv.matFromArray(1, 3, CV_8UC1, [cutNumber[1], 0, 0]);
  let upperb2= cv.matFromArray(1, 3, CV_8UC1, [50, 255, 255]);
 
  cv.inRange(res, lowerb, upperb, res1);
  cv.inRange(res, lowerb2, upperb2, res2);

  let M = cv.Mat.ones(10, 10, cv.CV_8UC1);
  cv.morphologyEx(res1,res1, cv.MORPH_OPEN, M);
  cv.morphologyEx(res2,res2, cv.MORPH_OPEN, M);

  let contours = new cv.MatVector();
  let hierarchy = new cv.Mat();
  cv.findContours(res1, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
  
  let contours2 = new cv.MatVector();
  let hierarchy2 = new cv.Mat();
  cv.findContours(res2, contours2, hierarchy2, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);
  

  let largestContourIndex = -1;
  let largestContourArea = -1;
  
  // Encuentra el índice y el área del contorno más grande
  for (let i = 0; i < contours.size(); i++) {
    const area = cv.contourArea(contours.get(i));
    if (area > largestContourArea) {
      largestContourArea = area;
      largestContourIndex = i;
    }
  }
  
  if (largestContourIndex !== -1 & largestContourArea>cutNumber[2]*0.1) {
    // Dibuja el contorno más grande en la imagen 'image2'
    cv.drawContours(image2, contours, largestContourIndex, [255, 255, 255, 1], 4);
  }

  return {
    image: image2,
    //JM no se usa esto
    //croppedImage: original,
    detectedPixels: cv.countNonZero(res1),
    detectedPixels2: cv.countNonZero(res2),
  };
}

// returns true if every pixel's uint32 representation is 0 (or "blank")
export function  isCanvasBlank(canvas) {
  const context = canvas.getContext('2d');

  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, canvas.width, canvas.height).data.buffer
  );

  return !pixelBuffer.some(color => color !== 0);
}
